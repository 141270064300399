<template>
  <div class="content">
    <div class="row">
      <div class="col">
        <div class="form-check">
          <input class="form-check-input" v-model="showPendingSubs" type="checkbox" id="pending" />
          <label class="form-check-label" for="pending">Include completed subscriptions</label>
        </div>
      </div>
      <div class="col-auto">{{subsList.meta ? subsList.meta.total : 0}} subscriptions</div>
    </div>
    <div class="row">
      <div class="col">
        <div class="table-container">
          <table class="table align-middle">
            <thead class="head">
              <tr>
                <th class="ps-4">ID</th>
                <th></th>
                <th>Product</th>
                <th>Variation</th>
                <th>Email</th>
                <th>Subscribed</th>
                <th v-if="showPendingSubs">Email sent at</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sub in subsList.data" :key="sub.id" :class="{'completed': sub.deleted_at}">
                <td class="ps-4">{{sub.id}}</td>
                <td>
                  <div>
                    <img v-if="sub.product_image" :src="image(sub.product_image, 60)" alt loading="lazy" class="image" width="60px" />
                  </div>
                </td>
                <td>{{sub.product_name}}</td>
                <td>{{sub.variation_name}}</td>
                <td>{{sub.email}}</td>
                <td>{{formatValue(sub.created_at, 'longDate')}} ({{formatValue(sub.created_at, 'diffForHumans')}})</td>
                <td v-if="showPendingSubs">
                  <span v-if="sub.deleted_at">{{formatValue(sub.deleted_at, 'longDate')}} ({{formatValue(sub.deleted_at, 'diffForHumans')}})</span>
                </td>
                <td>
                  <a :href="pwa + '/products/view/' + sub.product_slug" target="_blank" class="vave-btn">Show product</a>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination class="paginator" :limit="4" align="center" :data="subsList" @pagination-change-page="getSubscriptions"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../http.js";
import pagination from "laravel-vue-pagination";
import { formatValue } from "./../formatters";
import imageProxy from "./../imageProxy";

export default {
  computed: {
    pwa() {
      return process.env.VUE_APP_PWAURL;
    },
  },
  data() {
    return {
      output: "",
      subsList: {},
      items: [],
      showPendingSubs: false,
    };
  },
  components: { pagination },
  mounted() {
    if (Object.keys(this.subsList).length <= 0) {
      this.getSubscriptions();
    }
  },
  watch: {
    showPendingSubs() {
      this.getSubscriptions();
    },
  },
  methods: {
    image: imageProxy.image,
    formatValue,
    showOutputModal() {
      this.$refs.outputModal.show();
    },
    setItems(items) {
      this.items = items;
    },
    getSubscriptions(page = 1, perPage = 10) {
      this.output = "";
      this.subsList = {};
      axios
        .get(
          "/api/admin/stock-subscriptions?page=" +
            page +
            "&per_page=" +
            perPage +
            "&includepending=" +
            this.showPendingSubs
        )
        .then((response) => {
          if (response.data.data) {
            this.subsList = response.data;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: white;
}
tbody tr {
  &:hover {
    background: #fafafa;
  }
  &.completed {
    background: #cae0e98a;
    &:hover {
      background: #cadfe9b6;
    }
  }
}
</style>